<template>
  <v-container class="foldable-layout" :class="layoutClass">
    <v-row>
      <v-col :cols="isFold ? mainCols + foldCols : mainCols" class="pa-0">
        <v-container class="main-container">
          <v-row>
            <v-container class="main-section">
              <slot name="main">
                <!-- メインコンテンツが入る -->
              </slot>
            </v-container>
          </v-row>
          <v-row>
            <v-col class="pa-0">
              <my-footer />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col v-if="!isFold" ref="fold-wrapper" :cols="foldCols" class="pa-0">
        <section ref="scroll-container" class="fold-container">
          <slot name="fold" :scroll-container="$refs['scroll-container']">
            <!-- フォールドのコンテンツが入る -->
          </slot>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import MyFooter from '@/components/base/my-footer.vue';

/**
 * 基本は 100% 幅だが、必要に応じてフォールド部分を表示することができるレイアウト
 */
@Component({ components: { MyFooter } })
export default class LayoutFoldable extends Vue {
  /** フォールド部分が非表示になっているか */
  @Prop() isFold!: boolean;

  /** フォールドを表示した時のメイン部分の幅 */
  @Prop({ default: 6 }) mainCols!: number;

  /** フォールドを表示した時のフォールド部分の幅 */
  @Prop({ default: 6 }) foldCols!: number;

  /** 画面幅が 100% かどうか */
  @Prop({ default: true }) fullWidth!: boolean;

  /** レイアウトに指定するスタイル */
  get layoutClass(): string[] {
    return this.fullWidth ? ['-fullWidth'] : [];
  }
}
</script>

<style lang="scss" scoped>
@import '../../layouts/default.scss';

.foldable-layout,
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: 0 12px;
}
.main-container,
.fold-container {
  height: calc(100vh - var(--header-area-offset));
  overflow-y: scroll;
}
.foldable-layout {
  // Vuetify のスタイルを上書き
  .row {
    flex: none;
    margin: 0 -12px;
  }
  // Vuetify のスタイルを上書き

  .main-container {
    .main-section {
      padding: 12px;
    }
  }

  &.-fullWidth {
    .main-container {
      .main-section {
        width: 100%;
        padding: 12px;
      }
    }
  }
}
</style>
